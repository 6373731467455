import React from 'react';
import GetOnAppStore from '../../../images/GetOnAppStore.png';
import GetOnPlayStore from '../../../images/GetOnPlayStore.png';

export default function DownloadFutyMobileComponent() {
  function handleGetOnStoreClick() {
    // eslint-disable-next-line no-undef
    fbq('track', 'GetApp');
    window.location.href = 'https://futyreferral.app.link/maldinimobile';
  }
  return (
    <div className="w-2/3 flex flex-col justify-center items-center gap-4 bg-[#181818] rounded-[16px] lg:hidden">
      <img
        src={GetOnAppStore}
        alt="GetOnAppStore"
        className="w-2/3 h-auto cursor-pointer"
        onClick={handleGetOnStoreClick}
      />
      <img
        src={GetOnPlayStore}
        alt="GetOnPlayStore"
        className="w-2/3 h-auto cursor-pointer"
        onClick={handleGetOnStoreClick}
      />
    </div>
  );
}
