import React from 'react';
import AestheticEllipse from '../../../images/ContestTAndCEllipse.png';
import RectanglePink from '../../../images/RectanglePink.png';

export default function TAndC() {
  return (
    <section className="max-h-full w-screen flex flex-col items-center text-[32px] lg:text-[48px] text-[#ffffff] font-['RocGrotesk'] gap-10 relative">
      <img
        src={AestheticEllipse}
        alt="ellipse"
        className="absolute w-2/7 lg:w-3/7 h-auto top-[30vh] lg:top-0 right-0"
      />
      <h1
        id="mainHeading"
        className="text-[#009A57] relative z-[1]"
      >
        <img
          src={RectanglePink}
          alt="PinkAestheticRectangle"
          className="w-[24px] h-[24px] lg:w-[32px] lg:h-[32px] absolute z-[-1] right-[-2.75vh] top-[1vh]"
        />
        Contest T&C
      </h1>
      <div
        id="mainBody"
        className="h-full w-5/6 flex flex-col gap-8 text-[24px]"
      >
        <div>
          <h2 className="mb-2 text-[#B3EF00]">Rules for Entry</h2>
          <p className="text-[18px] font-inter text-[#6E6E6E]">
            <p className="hidden lg:inline-block">
              1. Download the FUTY app using the QR code on this page.
            </p>
            <p className="lg:hidden">
              1. Download the FUTY app by clicking the iOS/Android buttons.
            </p>
            <br />
            2. Create a team for at least one of the Week 1 matches of the
            Premier League. You are invited to create teams for more matches of
            your choice. <br />
            3. Submit your team and wait for us to publish the results by{' '}
            <b>22nd of August</b>.{' '}
          </p>
        </div>

        <div>
          <h2 className="mb-2 text-[#B3EF00]">Participation deadlines</h2>
          <p className="text-[18px] font-inter text-[#6E6E6E]">
            All teams must be submitted by <b>2:30 PM, 19th August</b>.
          </p>
        </div>
        <div>
          <h2 className="mb-2 text-[#B3EF00]">Winner</h2>
          <p className="text-[18px] font-inter text-[#6E6E6E]">
            The winner will be chosen through a random lucky draw among all
            participants who submitted a team.
          </p>
        </div>
        <div>
          <h2 className="mb-2 text-[#B3EF00]">About intimating the winner</h2>
          <p className="text-[18px] font-inter text-[#6E6E6E]">
            {' '}
            The winner will be announced on our social media handles.The winner
            will also be contacted directly via phone call for confirmation and
            prize details.
          </p>
        </div>
        <div>
          <h2 className="mb-2 text-[#B3EF00]">Disclaimers</h2>
          <p className="text-[18px] font-inter text-[#6E6E6E] pb-2">
            {' '}
            <b className="text-[#ffffff] font-interSemi">- Eligibility:</b>{' '}
            Contest is open to residents of the UK aged 18 and over.
            <br />
            <b className="text-[#ffffff] font-interSemi">
              - Multiple Entries:
            </b>{' '}
            Only one entry per person. Multiple entries will result in
            disqualification.
            <br />
            <b className="text-[#ffffff] font-interSemi">
              - Non-Transferable:
            </b>{' '}
            The prize is non-transferable and cannot be exchanged for cash.
            <br />
            <b className="text-[#ffffff] font-interSemi">
              - Disqualification:
            </b>{' '}
            FUTY reserves the right to disqualify any participant found to be
            violating the contest rules or engaging in any fraudulent activity.
            <br />
            <b className="text-[#ffffff] font-interSemi">- Publicity:</b> By
            entering the contest, participants agree to the use of their name
            and likeness for promotional purposes without additional
            compensation.
            <br />
            <b className="text-[#ffffff] font-interSemi">
              - Limitation of Liability:
            </b>{' '}
            FUTY is not responsible for any technical issues or errors that may
            occur during the contest.
            <br />
          </p>
          <span className="text-[18px] text-[#6E6E6E]">
            By participating in this contest, you agree to these rules and the
            decisions of FUTY, which are final and binding in all respects. Good
            luck!
          </span>
        </div>
      </div>
    </section>
  );
}
