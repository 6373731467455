import React from 'react';
import QrCode from '../../../images/QRDemo.png';
import DownloadFutyMobileComponent from './DownloadFutyMobileComponent';

export default function ScanQrCode() {
  return (
    <section className="max-h-[200px] w-screen flex flex-col lg:flex-row items-center justify-center text-[32px] text-[#ffffff] font-['RocGrotesk'] gap-6 lg:gap-[100px]">
      <h1
        id=" textForQr"
        className="hidden lg:inline-block "
      >
        Scan the QR code to download FUTY
      </h1>
      <h1
        id="download"
        className="mb-[14px] lg:hidden text-[32px] text-[#009A57]"
      >
        Download FUTY
      </h1>
      <img
        src={QrCode}
        alt="QrCodeToStorelink"
        className="hidden w-[100px] h-[100px] mb-8 lg:inline-block"
      ></img>
      <DownloadFutyMobileComponent />
    </section>
  );
}
