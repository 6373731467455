import React from 'react';
import User1 from '../../../images/dannyHill.png';
import User2 from '../../../images/georgeHayes.png';
import ScanQrCode from './ScanQrCode';

export default function DownloadFutyOnIphoneAndAndroid() {
  const styles = {
    hrCommentBreak: {
      width: '100%',
      height: '1px',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 19.83%, #FFFFFF 50.48%, rgba(255, 255, 255, 0) 80.41%)',
      opacity: '0.6',
      flex: 'none !important',
      alignSelf: 'stretch',
      flexGrow: '0',
    },
  };
  return (
    <section className="max-h-full w-screen flex flex-col items-center text-[32px] lg:text-[48px] text-[#ffffff] font-['RocGrotesk'] gap-10 text-center pb-[65px]">
      <h1
        id="mainHeading"
        className="hidden lg:inline-block text-[#009A57]"
      >
        Download FUTY on <br className="lg:hidden" /> iPhone & Android
      </h1>
      <div
        id="mainBody"
        className="h-full w-5/6 flex flex-col items-center gap-8 text-[24px] text-center mb-8"
      >
        {' '}
        <ScanQrCode />
        <div
          className="flex flex-col lg:flex-row justify-between gap-[20px] lg:gap-8"
          id="userFeedbackContainer"
        >
          <div
            className="flex flex-col items-center gap-2 justify-evenly bg-[#171717] rounded-[16px] py-4 border-[1px] border-[#eeeeee] lg:border-0"
            id="user1Feedback"
          >
            <div className="flex items-center justify-center gap-10 ">
              <img
                className="w-[60px] h-auto"
                src={User1}
                alt="userImage"
              ></img>
              <div className="flex flex-col space-evenly font-interSemi">
                <span className="text-[16px] font-rocgrotesk">DANNY HILL</span>
                <span className="text-[14px] text-[#ffffff99]">
                  EMPLOYEE AT MORRISONS
                </span>
              </div>
            </div>
            <div style={styles.hrCommentBreak}></div>
            <span className="font-inter text-[16px] w-[11/12] font-[200] px-4">
              "FPL move over because FUTY is on another level. 100% the place to
              be for football lovers with the added bonus of weekly prizes."
            </span>
          </div>
          <div
            className="flex flex-col items-center gap-2 justify-evenly bg-[#171717] rounded-[16px] py-4 border-[1px] border-[#eeeeee] lg:border-0"
            id="user2Feedback"
          >
            <div className="flex items-center justify-center gap-[4px] lg:gap-10">
              <img
                className="w-[60px] h-auto"
                src={User2}
                alt="user2Image"
              ></img>
              <div className="flex flex-col space-evenly font-interSemi">
                <span className="text-[16px] font-rocgrotesk">
                  GEORGE HAYES
                </span>
                <span className="text-[14px] text-[#ffffff99]">
                  UCL FOOTBALL SOCIETY PRESIDENT
                </span>
              </div>
            </div>
            <div style={styles.hrCommentBreak}></div>
            <span className="font-inter text-[16px] w-[11/12] font-[200] px-4">
              "Hands down the best fantasy game - I added over 150 of my
              university mates to the MVP because everyone has to experience
              becoming a gaffer"
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
