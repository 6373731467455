import { useState } from 'react';
import loader from '../images/loader.webp';
import tickPng from '../images/tickGreen.png';

export default function SearchReferralCode() {
  const queryParams = new URLSearchParams(window.location.search);
  const [refCode, setRefCode] = useState(queryParams.get('userCode') || '');
  const [barCode, setBarCode] = useState(queryParams.get('code') || '');
  const [isValidBarCode, setIsValidBarCode] = useState(false);
  const [referralId, setReferralId] = useState('');

  const authorisationToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkFtcml0IHNpbmdoICIsInVzZXIiOiIzYWRlZGE5ZS1lODhkLTQ4MTYtYTRiNS1mNDRmYjUxMGQxODEiLCJzdWIiOiIzYWRlZGE5ZS1lODhkLTQ4MTYtYTRiNS1mNDRmYjUxMGQxODEiLCJyb2xlIjoiVXNlciIsImludGVybmFsIjp0cnVlLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJpYXQiOjE3MjI0NDU0MzEsImV4cCI6MTcyMzA1MDIzMX0.qvQYP-trdGNohDHeAtAXmBJVAi1VKx_cKrmWSxF9LUo';

  const validateBarCodeAndReferralCode = async () => {
    console.log(refCode, barCode);
    if (barCode && barCode.length) {
      try {
        // const url = `https://api.futy.uk/api/v1/promotion/valid?code=${barCode}`;
        const url = `https://api.futy.uk/api/v1/promotion/valid?code=${barCode}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authorisationToken,
          },
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.statusCode === 200 && responseData.data.valid) {
          setIsValidBarCode(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsValidBarCode(false);
      }
    }
  };
  if (!isValidBarCode) validateBarCodeAndReferralCode();

  // const for the loader to be shown on screen
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] =
    useState(true);

  const [isValidReferralCode, setIsValidReferralCode] = useState(false);

  const searchReferralCode = async () => {
    if (!refCode || !refCode.length) {
      alert('Please enter code');
      return;
    }
    try {
      //   const url = `https://api.futy.uk/api/v1/promotion/userBonus/search`;
      let body = {
        code: barCode,
        userCode: refCode,
      };
      const url = 'https://api.futy.uk/api/v1/promotion/userBonus/search';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorisationToken,
        },
        body: JSON.stringify(body),
      });
      const responseData = await response.json(); // Extract JSON data from response
      console.log(responseData);
      if (responseData.statusCode === 201) {
        if (responseData.data.id && responseData.data.id.length) {
          setIsValidReferralCode(true);
          setReferralId(responseData.data.id);
        } else {
          alert(`Error: Invalid Code`);
        }
        return;
      } else if (responseData?.statusCode === 400 && responseData?.message)
        alert(responseData?.message);
      //   setIsValidReferralCode(true);
    } catch (error) {
      console.error('Error::', error);
      setShouldRenderSubmitButton(true);
    }
  };
  const [isRedemptionSuccessful, setIsRedemptionSuccessful] = useState(false);

  const redeemReferralCode = async () => {
    if (!refCode || !refCode.length) {
      alert('Please enter code');
      return;
    }
    try {
      console.log(referralId);
      const url = `https://api.futy.uk/api/v1/promotion/userBonus/${referralId}/redeem`;
      console.log('comes here');
      // const url = `https://api.futy.uk/api/v1/promotion/userBonus/${referralId}/redeem`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorisationToken,
        },
      });
      console.log(response);
      const responseData = await response.json(); // Extract JSON data from response
      console.log(responseData);
      if (responseData?.data?.error) {
        alert(responseData.data.error);
      } else if (responseData?.data?.success) {
        setIsRedemptionSuccessful(true);
        return;
      }

      //   setIsRedemptionSuccessful(true);
    } catch (error) {
      alert(error.message);
      console.error('Error:', error);
      // Handle error state
      setShouldRenderSubmitButton(true);
    }
  };

  const mobileFormStyles = {
    wrapper1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      displaywidth: '100vw',
      height: '100vh',
      zIndex: 1,
    },
    wrapperStyle: {
      width: '360px',
      minHeight: '280px',
      maxHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderRadius: '1em',
      backgroundColor: 'rgba(24, 24, 27, 0.7)',
      border: '0px',
    },
  };

  return !isLoading ? (
    !isValidBarCode ? (
      <div
        id='outerWrapper'
        style={mobileFormStyles.wrapper1}
      >
        <div
          style={{
            backgroundColor: 'rgb(24, 24, 27)',
            minHeight: '180px',
            maxHeight: '300px',
            width: '300px',
            borderRadius: '1em',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: "'RocGrotesk', sans-serif",
            fontSize: '1.2em',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <p>The link seems incorrect.</p>
          <p>Please reload and try again.</p>
          <p>If the issue persists, contact FUTY team.</p>
        </div>
      </div>
    ) : isRedemptionSuccessful ? (
      <div
        id='outerWrapper'
        style={mobileFormStyles.wrapper1}
      >
        <div
          style={{
            backgroundColor: 'rgb(24, 24, 27)',
            minHeight: '180px',
            maxHeight: '300px',
            width: '300px',
            borderRadius: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: "'Inter', sans-serif",
            fontSize: '20px',
            textAlign: 'center',
            color: 'white',
          }}
        >
          <p>
            Code redemption successful{' '}
            <span
              style={{
                color: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontSize: '24px',
              }}
            >
              *{refCode}*
            </span>
            <br />
          </p>
        </div>
      </div>
    ) : (
      <div
        id='outerWrapper'
        style={mobileFormStyles.wrapper1}
      >
        <div style={mobileFormStyles.wrapperStyle}>
          <span
            style={{
              fontFamily: "'RocGrotesk', sans-serif",
              fontWeight: 600,
              fontSize: '20px',
              color: '#fff',
              textAlign: 'center',
              marginTop: '10px',
              padding: '0.1em',
            }}
          >
            {isValidReferralCode
              ? `Redeem Code`
              : `Please enter the code to check validity`}
          </span>
          <div
            style={{
              width: '90%',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <input
              type='text'
              id='refCode'
              name='refCode'
              value={refCode}
              onChange={(e) => setRefCode(e.target.value)}
              disabled={isValidReferralCode}
              style={{
                width: '100%',
                border: '0.1em rgba(255,255,255,0.2) solid',
                paddingLeft: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                borderRadius: '0.5em',
                fontFamily: "'Inter',sans-serif",
                fontSize: '16px',
                color: 'rgba(255,255,255,0.5)',
                height: '35px',
                fontWeight: 'bolder',
                letterSpacing: '0.1em',
              }}
              placeholder='Enter Code'
            ></input>
            {isValidReferralCode && (
              <img
                style={{
                  position: 'absolute',
                  height: '24px',
                  width: '24px',
                  right: '12px',
                }}
                src={tickPng}
                alt='validTick'
              />
            )}
          </div>

          {/* </div> */}
          {isValidReferralCode ? (
            <button
              onClick={redeemReferralCode}
              style={{
                width: '95%',
                height: '35px',
                borderWidth: '0',
                borderRadius: '0.5em',
                backgroundColor: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '16px',
                color: '#ffffff',
                textAlign: 'center',
                cursor: 'pointer',
                letterSpacing: '2px',
                marginBottom: '10px',
                padding: '0.25em 0em',
                zIndex: 2,
              }}
            >
              {shouldRenderSubmitButton ? (
                <span>REDEEM</span>
              ) : (
                <img
                  src='https://fonts.gstatic.com/s/e/notoemoji/latest/2699_fe0f/512.gif'
                  alt='⚙'
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                  }}
                />
              )}
            </button>
          ) : (
            <button
              onClick={searchReferralCode}
              style={{
                width: '95%',
                height: '35px',
                borderWidth: '0',
                borderRadius: '0.5em',
                backgroundColor: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '16px',
                color: '#ffffff',
                textAlign: 'center',
                cursor: 'pointer',
                letterSpacing: '2px',
                marginBottom: '10px',
                padding: '0.25em 0em',
                zIndex: 2,
              }}
            >
              {shouldRenderSubmitButton ? (
                <span>CHECK VALIDITY</span>
              ) : (
                <img
                  src='https://fonts.gstatic.com/s/e/notoemoji/latest/2699_fe0f/512.gif'
                  alt='⚙'
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                  }}
                />
              )}
            </button>
          )}

          <span
            style={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: 400,
              fontSize: '10px',
              color: '#fff',
              textAlign: 'center',
              marginBottom: '0.75em',
              width: '90%',
            }}
          >
            If code is not pre-filled above, please manually fill the code
            received from the customer.
          </span>
        </div>
      </div>
    )
  ) : (
    <div
      id='outerWrapper'
      style={mobileFormStyles.wrapper1}
    >
      <div
        style={{
          ...mobileFormStyles.wrapperStyle,
          background: 'transparent',
          borderRadius: '100%',
          overflow: 'hidden',
        }}
      >
        <img
          src={loader}
          alt=''
        />
      </div>
    </div>
  );
}
