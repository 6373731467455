import React from 'react';
import TAndC from './components/TAndC';
import GetInvloved from './components/GetInvolved';
import DownloadFutyOnIphoneAndAndroid from './components/DownloadFutyOnIphoneAndAndroid';
import WhyFuty from './components/WhyFuty';
import ScanQrCode from './components/ScanQrCode';
import HeroSection from './components/HeroSection';
import DownloadNowBottomSticky from './components/DownloadNowBottomSticky';

export default function JerseyGiveaway() {
  return (
    <>
      <div className="w-screen overflow-hidden flex flex-col gap-[60px] lg:gap-[75px] bg-[#121312] z-[-2]">
        <HeroSection />
        <ScanQrCode />
        <GetInvloved />
        <ScanQrCode />
        <TAndC />
        <WhyFuty />
        <DownloadFutyOnIphoneAndAndroid />
        <DownloadNowBottomSticky />
      </div>
    </>
  );
}
