import React from 'react';
import FutyLogo from '../../../images/FutyLogoWhite.png';
import BgImage from '../../../images/GiveawayHeroBackground.png';
import MaldiniJersey from '../../../images/MaldiniJersey.png';

export default function HeroSection() {
  return (
    <section className="h-[790px] w-screen flex flex-col items-center text-[48px] text-[#ffffff] font-['RocGrotesk'] gap-10 relative">
      <img
        id="mainHeading"
        src={FutyLogo}
        alt="FutyLogo"
        className="py-8 z-[1] scale-75 lg:scale-125"
      />
      <div
        id="mainBody"
        className="h-full w-11/12 flex flex-col items-center justify-evenly gap-8 lg:gap-12 text-center z-[1] lg:flex-row"
      >
        <span className="font-rocgrotesk text-[38px] text-[#C0EB4B] lg:hidden">
          Premier&nbsp;league <br />
          giveaway!
        </span>
        <div
          id="jerseyContainer"
          className="w-[240px] h-[220px] lg:w-[600px] lg:h-[730px] z-[-1] flex flex-col items-center justify-start lg:bg-[#1917194d] lg:pt-4 gap-2"
        >
          <img
            src={MaldiniJersey}
            alt="MaldiniGiveawayJersey"
            className="w-[490] h-[450]"
          />
          <span className="hidden font-rocgrotesk text-[32px] text-[#C0EB4B] lg:inline-block">
            This legendary jersey could be yours.
          </span>
        </div>
        <div
          id="GiveawaytextContainer"
          className="h-full flex flex-col justify-start gap-12 lg:gap-[100px]"
        >
          <span className="hidden font-roxgrotesk text-[88px] px-2 text-[#C0EB4B] lg:inline-block">
            Premier&nbsp;league <br /> giveaway!
          </span>
          <span className="font-rocgrotesk mb-[18px] text-[20px] text-[#C0EB4B] px-4 lg:hidden">
            Want to win this vintage <br />
            Maldini jersey?
          </span>

          <div className="flex flex-col items-center font-interSemi text-[14px] gap-[10px] lg:text-[20px] lg:gap-4">
            <span>Download FUTY</span>
            <div className="w-[200px] h-[2px] bg-[#324A43]"></div>
            <span>
              Create your team for any 1st week <br />
              match of the Premier League
            </span>
            <div className="w-[200px] h-[2px] bg-[#324A43]"></div>
            <span>Wait for results.</span>
          </div>
        </div>
      </div>
      <img
        src={BgImage}
        alt="BgImage"
        className="absolute w-full h-[790px]"
      />
    </section>
  );
}
