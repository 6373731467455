import React from 'react';

export default function DownloadNowBottomSticky() {
  function handleGetOnStoreClick() {
    // eslint-disable-next-line no-undef
    fbq('track', 'GetApp', { position: 'sticky-bottom' });
    window.location.href = 'https://futyreferral.app.link/maldinimobile';
  }
  return (
    <section className="h-[65px] w-full lg:hidden fixed bottom-0 flex items-center justify-center z-[1] bg-[#373737]">
      <button
        className="h-2/3 w-4/5 bg-[#B3EF00] rounded-lg text-[24px] font-rocGrotesk tracking-tight border-solid border-[#000000] border-[1px]"
        onClick={handleGetOnStoreClick}
      >
        Download&nbsp;&nbsp;&nbsp;Now
      </button>
    </section>
  );
}
