import { useState, useEffect } from 'react';
import logo from './images/FutyLogoWhite.png';
import footballWithLine from './images/FootballWithHalfLine.png';
import appStorePng from './images/appstore.png';
import playStorePng from './images/googleplay.png';
import gold from './images/Gold.png';
import silver from './images/Silver.png';
import bronze from './images/Bronze.png';
import plusSvg from './images/plusNew.svg';
import plusPng from './images/plus.png';
import minusPng from './images/minus.png';
import futyWithSquares from './images/futyLogoWithSquares.png';
import screen1 from './images/screen1.png';
import screen2 from './images/screen2.png';
import screen3 from './images/screen3.png';
import founderPng from './images/Harsh.png';
import linkedInPng from './images/linkedin.png';
import faqSupportSqaure from './images/faq-bg-square.png';
import user1 from './images/dannyHill.png';
import user2 from './images/georgeHayes.png';
import user3 from './images/sammyGarcia.png';
import instagramPng from './images/instagram.png';
import tiktokPng from './images/tiktok.png';
import linkedInWhitePng from './images/linkedinWhite.png';
import tickPng from './images/tickGreen.png';
import './App.css';
import MobileView from './MobileVersion';
import FaqSection from './components/FaqSection';
import useDynamicText from './hooks/useDynamicText';

export default function Home() {
  const [contactUsFormEmail, setContactUsFormEmail] = useState('');
  const [contactUsFormName, setContactUsFormName] = useState('');
  const [contactUsFormContent, setContactUsFormContent] = useState('');
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleContactUsSubmit = async (e) => {
    e.preventDefault();
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let source = queryParams.get('source')
        ? queryParams.get('source')
        : 'unknown';

      let formData = {
        email: contactUsFormEmail,
        name: contactUsFormName,
        message: contactUsFormContent,
        source: source,
      };
      // eslint-disable-next-line no-undef
      ttq.track('DownloadNow', { source: 'd' + source });
      if (!formData.email || !formData.name || !formData.message) {
        alert('email, name, reason to contact are all mandatory');
        return;
      }
      console.log(formData);
      setIsMessageSent(true);
      const url = 'https://api.futygame.uk/v0/contactUs';
      // const url = 'http://localhost:9000/v0/contactUs';

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console

      setTimeout(() => {
        setContactUsFormContent('');
        setContactUsFormEmail('');
        setContactUsFormName('');
        setIsMessageSent(false);
      }, 180000);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const styles = {
    dynamicText: {},
    contactUsLineBreak: {
      width: '100%',
      height: '0.1em',

      background: '#FFFFFF',
      opacity: '0.2',
    },
    commentBox: {
      color: '#fff',
      border: '0',
      borderRadius: '1em',
      width: '80%',
      height: '20vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      backgroundColor: 'rgba(24,24,24,1)',
    },
    hrCommentBreak: {
      width: '100%',
      height: '1px',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 19.83%, #FFFFFF 50.48%, rgba(255, 255, 255, 0) 80.41%)',
      opacity: '0.6',
      flex: 'none !important',
      alignSelf: 'stretch',
      flexGrow: '0',
    },
    label: {
      display: 'block',
      position: 'relative',
      padding: '0.3125em',
      cursor: 'pointer',
    },
    input: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
    },
    span: {
      position: 'relative',
      display: 'inline-block',
      width: '0.5em',
      height: '0.5em',
      backgroundColor: '#ffffff',
      borderRadius: '50%',
      border: '0.25em solid white',
    },
    selected: {
      border: '0.25em solid var(--primary-green)',
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'var(--background-primary)',
      width: '100%',
      zIndex: '-10',
      overflowX: 'hidden',
      position: 'absolute',
    },
    mainPageContainer: {
      width: '86%',
      overflow: 'hidden',
      minHeight: '600px',
      // border: '0.0625em solid white',
    },

    ellipse33: {
      width: '28em',
      position: 'absolute',
      height: '33em',
      right: 'calc(20vw)',
      top: 'calc(15vh)',
      background:
        'linear-gradient(98.24deg, rgb(0, 104, 255) 0%, rgb(255, 84, 0) 34.51%, rgb(236, 0, 130) 57.27%, rgb(178, 239, 1) 100%)',
      opacity: '0.4',
      filter: 'blur(90px)',
      transform: ' rotate(55.83deg)',
      pointerEvents: 'none',
    },

    ellipse34: {
      position: 'absolute',
      width: '20em',
      height: '15em',
      left: 'calc(30%)',
      top: 'calc(20% + 100vh)',
      background: 'linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%)',
      opacity: '0.5',
      filter: 'blur(13.125em)',
      transform: 'rotate(190.83deg)',
      pointerEvents: 'none',
    },
    ellipse35: {
      position: 'absolute',
      width: '20em',
      height: '25em',
      left: 'calc(-5%)',
      top: ' 125vh',
      background:
        'linear-gradient(98.24deg, #FF5400 0%, #0068FF 48.77%, #B2EF01 100%)',
      opacity: '0.5',
      filter: 'blur(10em)',
      transform: 'rotate(82.83deg)',
      pointerEvents: 'none',
    },
    ellipse36: {
      position: 'absolute',
      width: '20em',
      height: '25em',
      bottom: '250px',
      background:
        'linear-gradient(98.24deg, #0068FF 0%, #FF5400 34.51%, #EC0082 57.27%, #B2EF01 100%)',
      opacity: '0.4',
      filter: 'blur(120px)',
      transform: 'rotate(55.83deg)',
      pointerEvents: 'none',
    },
  };
  const [phone, setPhone] = useState('');

  // Mobile based constants
  const [isMobile, setIsMobile] = useState(false);

  function scrollToHash() {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  // Call the function when the page loads
  window.onload = scrollToHash;

  useEffect(() => {
    console.log(window.innerWidth);

    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < window.innerHeight); // Adjust threshold as needed
    };

    updateIsMobile(); // Check initially
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);
  useDynamicText();

  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] =
    useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const queryParams = new URLSearchParams(window.location.search);
      let source = queryParams.get('source')
        ? queryParams.get('source')
        : 'unknown';

      let formData = {
        mobile: '+44' + phone,
        smsTemplate: 'GET_DOWNLOAD_APP_LINK',
      };
      if (
        (phone != null && phone !== undefined && phone.length !== 10) ||
        !formData.smsTemplate
      ) {
        alert(
          'Phone number is invalid . Please fill the phone number correctly'
        );
        return;
      }
      // eslint-disable-next-line no-undef
      fbq('track', 'DownloadNow', { platfrom: 'desktop', source: source });

      // eslint-disable-next-line no-undef
      ttq.track('DownloadNow', { platform: 'desktop', source: source });
      // eslint-disable-next-line no-undef
      gtag('event', 'DownloadNow', { platfrom: 'desktop', source: source });
      setShouldRenderSubmitButton(false);
      const response = await fetch(
        'https://api.futy.uk/api/v1/notification/sendCustomSms',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console
      alert('SMS sent. Please check your mobile');
      setShouldRenderSubmitButton(true);
    } catch (error) {
      console.error('Error:', error);
      // Handle error state
      setShouldRenderSubmitButton(true);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return isMobile ? (
    <MobileView />
  ) : (
    <main style={styles.rootContainer}>
      <div style={styles.ellipse35}></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: '0px',
          width: 'calc(100vw - 4em)',
          padding: '0.5em 2em',
          zIndex: '11',
          background: '#000',
          height: '85px',
        }}
      >
        <img
          src={logo}
          alt="futy_logo"
          width={140}
          style={{
            position: 'relative',
            left: '4vw',
          }}
        />
        <button
          onClick={scrollToTop}
          style={{
            width: '20%',
            position: 'relative',
            height: '5vh',
            borderWidth: '0',
            borderRadius: '0.25em',
            backgroundColor: 'var(--primary-green)',
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: '600',
            fontSize: '1.2em',
            color: '#fff',
            textAlign: 'center',
            cursor: 'pointer',
            right: '4vw',
          }}
        >
          DOWNLOAD NOW
        </button>
      </div>
      <div style={styles.ellipse33}></div>
      <div
        className="container-fluid"
        style={{ ...styles.mainPageContainer, minHeight: '800px' }}
        id="registerSubPageContainer"
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'space-between',
            position: 'relative',
            top: '28.5vh',
            color: 'white',
            width: '100%',
            minHeight: '500px',
          }}
        >
          <div>
            <span
              style={{
                display: 'block',
                marginBottom: '0.625em', // 0.625em -> 8 - 12 - 16 px for the root em sizes
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '700',
                fontSize: '3em',
                color: '#ffffff',
              }}
            >
              The most immersive{' '}
              <b
                style={{
                  fontSize: '1.1em',
                  color: 'var(--primary-green)',
                  marginBottom: '2em',
                }}
              >
                fantasy football{' '}
              </b>
              app
            </span>
            <span
              style={{
                display: 'block',
                marginBottom: '0',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
                fontSize: '1.3em',
                lineHeight: '1.75em',
                color: '#A1A1AA',
              }}
            >
              <span>Free to </span>
              <span
                class="dynamic-text"
                style={{ position: 'relative' }}
              ></span>
            </span>

            <span
              style={{
                marginBottom: '0.625em',
                display: 'inline-block',
                background: `-webkit-linear-gradient(left, #2196F3, #4CAF50)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 400,
                fontSize: '1.3em',
                lineHeight: '1.75em',
              }}
            >
              Be the MANAGER
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              position: 'relative',
              top: '-8%',
              right: '0',
              width: '35vw',
              height: '44vh',
              borderRadius: '1em',
              backgroundColor: 'rgba(24, 24, 27, 0.7)',
              minHeight: '425px',
              // boxShadow: '-0.1em -0.1em 0.1em rgba(0, 0, 0, 0.8)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '83%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5em',
                marginBottom: '1em',
              }}
            >
              <span
                style={{
                  fontFamily: "'RocGrotesk', sans-serif",
                  fontWeight: 600,
                  fontSize: '2.4em',
                  color: '#fff',
                }}
              >
                Download now on play store and appstore
              </span>
              <span
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 500,
                  fontSize: '1.3em',
                  color: '#fff',
                  letterSpacing: '0.15em',
                  textAlign: 'center',
                }}
              >
                Join Today | Play Premier League Games for free
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '83%',
                height: '41%',
              }}
            >
              {/* <label
                htmlFor="email"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Phone Number
              </label> */}
              {/* <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '10% 85%',
                  justifyContent: 'space-between',
                }}
              >
                <input
                  type="text"
                  id="countryCode"
                  name="countryCode"
                  value="+44"
                  disabled
                  style={{
                    border: '0.1em rgba(255,255,255,0.2) solid',
                    padding: '0.5em 0em 0.5em 0em',
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    color: 'white',
                    borderRadius: '0.5em',
                    height: '33%',
                    marginBottom: '2.5em',
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: 'normal',
                    fontSize: '1em',
                    textAlign: 'center',
                  }}
                />
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{
                    border: '0.1em rgba(255,255,255,0.2) solid',
                    padding: '0.5em 0em 0.5em 1.25em',
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    color: 'white',
                    borderRadius: '0.5em',
                    height: '33%',
                    marginBottom: '2.5em',
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: 'normal',
                    fontSize: '0.9em',
                  }}
                  placeholder="1234 567890"
                  title="Enter a valid phone number"
                  pattern="^\d{4} \d{6}$"
                  required
                />
              </div> */}

              {/* <span
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: 400,
                  fontSize: '1em',
                  color: '#fff',
                }}
              >
                
              </span> */}
            </div>

            <button
              onClick={handleSubmit}
              style={{
                width: '83%',
                height: '12%',
                borderWidth: '0',
                borderRadius: '0.5em',
                backgroundColor: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '1em',
                color: '#fff',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              {shouldRenderSubmitButton ? (
                <span>DOWNLOAD NOW</span>
              ) : (
                <img
                  src="https://fonts.gstatic.com/s/e/notoemoji/latest/2699_fe0f/512.gif"
                  alt="⚙"
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                  }}
                />
              )}
            </button>
          </div>
        </div>
      </div>
      <img
        src={footballWithLine}
        alt="footballWithLine"
        style={{
          width: '100vw',
        }}
      />
      <div
        style={{
          ...styles.mainPageContainer,
          position: 'relative',
        }}
        id="userFeedbackSubPageContainer"
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '35% 65%',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              height: '100vh',
              gap: '5%',
              overflow: 'hidden',
            }}
          >
            <span
              style={{
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '3em',
                color: '#fff',
              }}
            >
              Users Love FUTY
            </span>
            <button
              onClick={scrollToTop}
              style={{
                height: '5%',
                width: '60%',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '1em',
                color: '#fff',
                borderRadius: '0.5em',
                border: '0',
                backgroundColor: 'var(--primary-green)',
                cursor: 'pointer',
              }}
            >
              DOWNLOAD NOW
            </button>
          </div>
          {/* <div
            style={{
              // border: '2px solid red',
              left: '35%',
              zIndex: '1',
              position: 'absolute',
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(to bottom, rgba(9,10,9, 1) 0%,  rgba(9,10,9 ,1) 5%, rgba(9,10,9, 0) 20%,rgba(9,10,9, 0) 80%, rgba(9,10,9, 1) 95%,  rgba(9,10,9, 1) 100%)',
            }}
          ></div> */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <div style={styles.commentBox}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  justifyContent: 'center',
                  gap: '1em',
                }}
              >
                <img
                  className="feedbackImage"
                  src={user1}
                  alt="userImage"
                  style={{
                    width: '3.5em',
                    height: '3.5em',
                  }}
                ></img>
                <div
                  className="nameAndCompany"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <span
                    style={{
                      fontFamily: "'RocGrotesk', sans-serif",
                      fontWeight: '600',
                      fontSize: '1.125em',
                      color: '#fff',
                    }}
                  >
                    DANNY HILL
                  </span>
                  <span
                    style={{
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: '400',
                      fontSize: '0.875em',
                      color: 'rgba(255,255,255,0.6)',
                    }}
                  >
                    EMPLOYEE AT MORRISONS
                  </span>
                </div>
              </div>
              <div style={styles.hrCommentBreak}></div>
              <span
                className="feedback"
                style={{
                  width: '95%',
                  textAlign: 'center',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '400',
                  fontSize: '1em',
                }}
              >
                "FPL move over because FUTY is on another level. 100% the place
                to be for football lovers with the added bonus of weekly
                prizes."
              </span>
            </div>
            <div style={styles.commentBox}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  justifyContent: 'center',
                  gap: '1em',
                }}
              >
                <img
                  className="feedbackImage"
                  src={user2}
                  alt="userImage"
                  style={{
                    width: '3.5em',
                    height: '3.5em',
                  }}
                ></img>
                <div
                  className="nameAndCompany"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <span
                    style={{
                      fontFamily: "'RocGrotesk', sans-serif",
                      fontWeight: '600',
                      fontSize: '1.125em',
                      color: '#fff',
                    }}
                  >
                    GEORGE HAYES
                  </span>
                  <span
                    style={{
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: '400',
                      fontSize: '0.875em',
                      color: 'rgba(255,255,255,0.6)',
                    }}
                  >
                    UCL FOOTBALL SOCIETY PRESIDENT
                  </span>
                </div>
              </div>
              <div style={styles.hrCommentBreak}></div>
              <span
                className="feedback"
                style={{
                  width: '95%',
                  textAlign: 'center',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '400',
                  fontSize: '1em',
                }}
              >
                "Hands down the best fantasy game - I added over 150 of my
                university mates to the MVP because everyone has to experience
                becoming a gaffer"
              </span>
            </div>
            <div style={styles.commentBox}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  justifyContent: 'center',
                  gap: '1em',
                }}
              >
                <img
                  className="feedbackImage"
                  src={user3}
                  alt="userImage"
                  style={{
                    width: '3.5em',
                    height: '3.5em',
                  }}
                ></img>
                <div
                  className="nameAndCompany"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <span
                    style={{
                      fontFamily: "'RocGrotesk', sans-serif",
                      fontWeight: '600',
                      fontSize: '1.125em',
                      color: '#fff',
                    }}
                  >
                    SAMMY GARCIA
                  </span>
                  <span
                    style={{
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: '400',
                      fontSize: '0.875em',
                      color: 'rgba(255,255,255,0.6)',
                    }}
                  >
                    BRISTOL FOOTBALL SOCIETY PRESIDENT
                  </span>
                </div>
              </div>
              <div style={styles.hrCommentBreak}></div>
              <span
                className="feedback"
                style={{
                  width: '95%',
                  textAlign: 'center',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '400',
                  fontSize: '1em',
                }}
              >
                "The head to head structure of FUTY is exciting for people as
                they feel every game is a new challenge.People are loving the
                game."
              </span>
            </div>
          </div>

          {/* <div
            className='commentsSubParent primary'
            style={{
              // marginTop: '10vh',
              top: '0vh',
              left: '35%',
              width: '60%',
              height: '100vh',
              position: 'absolute',
              display: 'grid',
              gridTemplateColumns: '47.5% 47.5%',
              justifyContent: 'space-between',
              overflow: 'hidden',
            }}
          >
            <div
              className='column1Comments'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '4em',
              }}
            >
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
            </div>
            <div
              className='column2Comments'
              style={{
                position: 'relative',
                top: '-10%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
            </div>
          </div>
          <div
            className='commentsSubParent secondary'
            style={{
              // marginTop: '10vh',
              top: '80vh',
              left: '35%',
              width: '60%',
              height: '100vh',
              position: 'absolute',
              display: 'grid',
              gridTemplateColumns: '47.5% 47.5%',
              justifyContent: 'space-between',
              overflow: 'hidden',
            }}
          >
            <div
              className='column1Comments'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '4em',
              }}
            >
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
            </div>
            <div
              className='column2Comments'
              style={{
                position: 'relative',
                top: '-10%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
              <div style={styles.commentBox}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    justifyContent: 'center',
                    gap: '1em',
                  }}
                >
                  <img
                    className='feedbackImage'
                    src={user1}
                    alt='userImage'
                    style={{
                      width: '3.5em',
                      height: '3.5em',
                    }}
                  ></img>
                  <div
                    className='nameAndCompany'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "'RocGrotesk', sans-serif",
                        fontWeight: '600',
                        fontSize: '1.125em',
                        color: '#fff',
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        fontFamily: "'Inter', sans-serif",
                        fontWeight: '400',
                        fontSize: '0.875em',
                        color: 'rgba(255,255,255,0.6)',
                      }}
                    >
                      CTO at Imagine
                    </span>
                  </div>
                </div>
                <div style={styles.hrCommentBreak}></div>
                <span
                  className='feedback'
                  style={{
                    width: '95%',
                    textAlign: 'center',
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: '400',
                    fontSize: '1em',
                  }}
                >
                  Kuch to log kahenge, logo . Kuch to log kahenge, logo ka kaam
                  hai kehna.
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div
        style={styles.mainPageContainer}
        id='prizesSubPageContainer'
      >
        <div style={styles.ellipse34}></div>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '40% 60%',
            position: 'relative',
            width: '100%',
            minHeight: '580px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              color: 'white',
            }}
          >
            <span
              style={{
                fontFamily: "'RocGrotesk',sans-serif",
                fontWeight: '600',
                fontSize: '3.625em',
              }}
            >
              Prizes
            </span>
            <span
              style={{
                color: '#6e6e6e',
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1.3em',
                width: '50%',
              }}
            >
              In FUTY, every move on the pitch reflects in your game plan, and
              every successful strategy deserves its trophy. That's why we've
              lined up an array of spectacular prizes for our top tacticians.
              The higher you rank, the bigger your reward. Here's what's at
              stake:
            </span>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '33.33% 33.33% 33.33%',
            }}
          >
            <div
              style={{
                position: 'relative',
                top: '22%',
                height: '78%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  color: 'white',
                  height: '100%',
                }}
              >
                <img
                  src={silver}
                  alt='2ndPlace'
                  height={150}
                  width={150}
                />
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '600',
                    fontSize: '2.5em',
                  }}
                >
                  2nd Place
                </span>
                <span
                  style={{
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: '500',
                    fontSize: '1.25em',
                  }}
                >
                  £75 Amazon Gift Card
                </span>
              </div>
            </div>
            <div style={{ border: '0.1825em solid rgba(255,255,255,0.05)' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  color: 'white',
                  height: '100%',
                  position: 'relative',
                }}
              >
                <img
                  src={plusSvg}
                  alt='plusSign'
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    transform: 'translate(-50%, -50%)',
                  }}
                />
                <img
                  src={plusSvg}
                  alt='plusSign'
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    transform: 'translate(50%, -50%)',
                  }}
                />
                <img
                  src={plusSvg}
                  alt='plusSign'
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    transform: 'translate(-50%, 50%)',
                  }}
                />
                <img
                  src={plusSvg}
                  alt='plusSign'
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    transform: 'translate(50%, 50%)',
                  }}
                />
                <img
                  src={gold}
                  alt='1stPlace'
                  height={180}
                  width={180}
                />
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '600',
                    fontSize: '3.125em',
                  }}
                >
                  1st Place
                </span>
                <span
                  style={{
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: '500',
                    fontSize: '1.5em',
                  }}
                >
                  £100 Amazon Gift Card
                </span>
              </div>
            </div>
            <div
              style={{
                position: 'relative',
                top: '42%',
                height: '58%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  color: 'white',
                  height: '100%',
                }}
              >
                <img
                  src={bronze}
                  alt='3rdPlace'
                  height={125}
                  width={125}
                />
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '600',
                    fontSize: '1.875em',
                  }}
                >
                  3rd Place
                </span>
                <span
                  style={{
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: '500',
                    fontSize: '1em',
                  }}
                >
                  £50 Amazon Gift Card
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '2em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '20px',
            gap: '2em',
            textAlign: 'center',
          }}
        >
          <div style={{ ...styles.contactUsLineBreak, width: '60%' }}></div>
          <span
            style={{
              fontFamily: "'Inter',sans-serif",
              fontWeight: '500',
              fontSize: '1.5em',
              color: 'white',
            }}
          >
            4th-200th place win £5 amazon gift card
          </span>
        </div>
      </div> */}

      <div
        style={styles.mainPageContainer}
        id="whyFutySubPageContainer"
      >
        <div
          style={{
            color: 'white',
            position: 'relative',
            top: '7.5vh',
            display: 'grid',
            gridTemplateRows: '20% 65%',
            height: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontFamily: "'RocGrotesk',sans-serif",
                fontWeight: '600',
                fontSize: '3.25em',
              }}
            >
              Why FUTY?
            </span>
            <span
              style={{
                color: '#6e6e6e',
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1.3rem',
                textAlign: 'center',
                width: '50vw',
              }}
            >
              In a world where football never sleeps, FUTY stands out by
              bringing you to the heart of the action. Here's why you should
              play:
            </span>
          </div>
          <div
            style={{
              height: '100%',
              display: 'grid',
              gridTemplateColumns: '28% 44% 28%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5vh',
                }}
              >
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '500',
                    fontSize: '1.625em',
                  }}
                >
                  Focused Matchday Fun:
                </span>
                <span
                  style={{
                    color: '#6e6e6e',
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: 'normal',
                    fontSize: '1.3rem',
                  }}
                >
                  Forget the long haul. FUTY revolutionizes fantasy football
                  with a one-game matchday format.
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5vh',
                }}
              >
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '500',
                    fontSize: '1.625em',
                  }}
                >
                  Every Moment Counts Endless Excitement:
                </span>
                <span
                  style={{
                    color: '#6e6e6e',
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: 'normal',
                    fontSize: '1.3rem',
                  }}
                >
                  In FUTY, every pass, tackle, and goal equals points
                  heightening your fantasy football experience.
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={futyWithSquares}
                alt="futyAestheticLogo"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5vh',
                }}
              >
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '500',
                    fontSize: '1.625em',
                  }}
                >
                  Drop In; Drop Out:
                </span>
                <span
                  style={{
                    color: '#6e6e6e',
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: 'normal',
                    fontSize: '1.3rem',
                  }}
                >
                  Not up for a season-long commitment? No problem! Jump into any
                  gameweek and play whenever you want, hassle-free.
                </span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5vh',
                }}
              >
                <span
                  style={{
                    fontFamily: "'RocGrotesk',sans-serif",
                    fontWeight: '500',
                    fontSize: '1.625em',
                  }}
                >
                  Real rewards for your efforts:
                </span>
                <span
                  style={{
                    color: '#6e6e6e',
                    fontFamily: "'Inter',sans-serif",
                    fontWeight: 'normal',
                    fontSize: '1.3rem',
                  }}
                >
                  At FUTY, We go one step beyond just bragging rights. We
                  celebrate each win with real rewards for you to redeem.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ ...styles.mainPageContainer, height: '120vh' }}
        id="appComingSoonSubPageContainer"
      >
        {/* LEARNING - FLEX VS GRID here */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            position: 'relative',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: "'RocGrotesk',sans-serif",
              fontWeight: '600',
              fontSize: '3.25em',
            }}
          >
            FUTY app is out now !
          </div>
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              height: '100%',
              backgroundColor: 'rgba(33,33,33,0.3)',
              borderRadius: '1em',
              maxHeight: '35em',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                height: '100%',
              }}
            >
              <div>
                <img
                  src={screen1}
                  alt="screen1"
                  style={{ width: '18em' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '12vw',
                    height: '8vh',
                    position: 'relative',
                    top: '2.5vh',
                    borderRadius: '1em',
                    border: '0.0625em solid rgba(255,255,255,0.1)',
                    backgroundColor: 'black',
                  }}
                >
                  <img
                    src={playStorePng}
                    alt="playStorePng"
                    style={{
                      width: '2.75em',
                      height: '2.75em',
                    }}
                  />
                  <img
                    src={appStorePng}
                    alt="appStorePng"
                    style={{
                      width: '2.75em',
                      height: '2.75em',
                    }}
                  />
                </div>
                <img
                  src={screen2}
                  alt="screen2"
                  style={{ width: '16em' }}
                />
              </div>
              <div style={{ height: '100%' }}>
                <img
                  src={screen3}
                  alt="screen3"
                  style={{ width: '18em' }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            <img
              src={founderPng}
              alt="founderPng"
              style={{
                width: '3.5em',
                height: '3.5em',
                margin: '4em 0em 0.625em 0em',
              }}
            />
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1rem',
                textAlign: 'center',
                lineHeight: '1.4rem',
                width: '50%',
              }}
            >
              FUTY introduces a revolutionary platform aimed at transforming how
              fans engage with the game. By championing underrepresented teams
              and talents through an inventive reward system, FUTY seeks to
              enrich the league's engagement and broaden the fan base.
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '3vh',
                gap: '0.6rem',
              }}
            >
              <span
                style={{
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 600,
                  fontSize: '1.125em',
                  textAlign: 'center',
                }}
              >
                Harsh
              </span>
              <img
                src={linkedInPng}
                alt="linkedInPng"
                style={{
                  width: '1.25em',
                  height: '1.25em',
                }}
              />
            </div>
            <span
              style={{
                marginTop: '0.8rem',
                fontFamily: "'Inter',sans-serif",
                fontWeight: 400,
                fontSize: '0.875em',
                textAlign: 'center',
              }}
            >
              FOUNDER
            </span>
          </div>
        </div>
      </div>
      <FaqSection />

      {/* Contact Us section start */}
      <div
        style={{
          ...styles.mainPageContainer,
          width: '65%',
          height: '90vh',
          minHeight: '720px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
        id="contactUs"
      >
        {!isMessageSent ? (
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderRadius: '1em',
              width: '50vw',
              backgroundColor: 'rgba(24, 24, 27, 0.7)',
              color: '#fff',
              marginBottom: '1em',
            }}
          >
            <span
              style={{
                width: '83%',
                height: '12%',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '2em',
                textAlign: 'center',
                padding: '1em 0em',
              }}
            >
              Contact Us
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="email"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Email
              </label>
              <input
                type="email"
                id="contactUsFormEmail"
                name="contactUsFormEmail"
                value={contactUsFormEmail}
                onChange={(e) => setContactUsFormEmail(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  padding: '0.5em 0em 0.5em 1.25em',
                  borderWidth: '0',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  marginBottom: '2.5em',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1em',
                }}
                placeholder="you@somemail.com"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" // Regular expression for email validation
                title="Enter a valid email address"
                required
              ></input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="name"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                Name
              </label>
              <input
                type="text"
                id="contactUsFormName"
                name="contactUsFormName"
                value={contactUsFormName}
                onChange={(e) => setContactUsFormName(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  borderWidth: '0',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  height: '33%',
                  marginBottom: '2.5em',
                  padding: '0.5em 0em 0.5em 1.25em',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1em',
                }}
                placeholder="Your Name"
                required
              ></input>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
                height: '41%',
              }}
            >
              <label
                htmlFor="content"
                style={{
                  fontSize: '1em',
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: '500',
                  marginBottom: '0.75em',
                }}
              >
                What can we help you with ?
              </label>
              <textarea
                type="text"
                rows={4}
                id="contactUsFormContent"
                name="contactUsFormContent"
                value={contactUsFormContent}
                onChange={(e) => setContactUsFormContent(e.target.value)}
                style={{
                  border: '0.1em rgba(255,255,255,0.2) solid',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  color: 'white',
                  borderRadius: '0.5em',
                  height: '33%',
                  marginBottom: '1em',
                  padding: '0.5em 0em 0.5em 1.25em',
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1em',
                }}
                placeholder="I want help with... (or) Feedback: ..."
                required
              ></textarea>
            </div>
            <button
              onClick={handleContactUsSubmit}
              style={{
                width: '90%',
                height: '12%',
                borderWidth: '0',
                borderRadius: '0.5em',
                backgroundColor: 'var(--primary-green)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: '600',
                fontSize: '1em',
                color: '#fff',
                textAlign: 'center',
                cursor: 'pointer',
                padding: '1em 0em',
                marginBottom: '1em',
                zIndex: 2,
              }}
            >
              <span>SEND MESSAGE</span>
            </button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                position: 'relative',
                borderRadius: '2em',
                padding: '1em 0em',
                backgroundColor: 'rgba(24, 24, 27, 0.7)',
                color: '#fff',
                width: '73vh',
              }}
            >
              <img
                src={tickPng}
                alt="successPng"
                style={{
                  width: '7em',
                  height: '7em',
                  paddingBottom: '0.5em',
                }}
              />
              <span
                style={{
                  fontFamily: "'RocGrotesk', sans-serif",
                  fontWeight: 600,
                  fontSize: '2.5em',
                  paddingBottom: '0.5em',
                }}
              >
                Message Sent
              </span>
            </div>
          </div>
        )}

        <div style={styles.contactUsLineBreak}></div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40% 30% 30%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: '1.5em',
            }}
          >
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '1.5em',
                margin: '0em',
              }}
            >
              Our Location
            </span>
            <span
              style={{
                fontFamily: "'Inter',sans-serif",
                fontWeight: 'normal',
                fontSize: '1.25em',
                color: 'rgba(255,255,255,1)',
                margin: '0.5em 0em 1.5em 0em',
                textAlign: 'center',
              }}
            >
              Pachisi Ltd <br />
              20 Wenlock Road, London, N1 7GU
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '1.5em',
                margin: '0em',
              }}
            >
              Our Socials
            </span>
            <div
              style={{
                display: 'flex',
                width: '80%',
                justifyContent: 'space-evenly',
              }}
            >
              <a href="https://www.instagram.com/futy_uk/">
                <img
                  src={instagramPng}
                  alt="instaLogo"
                />
              </a>
              <a href="https://www.tiktok.com/@futy_uk">
                <img
                  src={tiktokPng}
                  alt="tiktokLogo"
                />
              </a>
              <a href="https://www.linkedin.com/company/futyyyuk/?originalSubdomain=uk">
                <img
                  src={linkedInWhitePng}
                  alt="linkedInLogo"
                />
              </a>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontFamily: "'RocGrotesk', sans-serif",
                fontWeight: 600,
                fontSize: '1.5em',
                margin: '0em',
              }}
            >
              Legal
            </span>
            <div
              style={{
                position: 'relative',
                top: '-5%',
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: '2px',
              }}
            >
              <a
                href="/termsAndConditions"
                style={{
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.15em',
                  color: 'rgba(255,255,255,1)',
                  textAlign: 'center',
                }}
              >
                Terms and Conditions
              </a>
              <a
                href="/privacyPolicy"
                style={{
                  fontFamily: "'Inter',sans-serif",
                  fontWeight: 'normal',
                  fontSize: '1.15em',
                  color: 'rgba(255,255,255,1)',
                  textAlign: 'center',
                }}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.ellipse36}></div>
      {/* Contact Us section end */}
    </main>
  );
}
