import logo from '../images/Logo.png';
export default function PrivacyPolicy() {
  return (
    <div
      style={{
        backgroundColor: 'var(--background-primary)',
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
        color: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          top: '0px',
          width: 'calc(100vw - 4em)',
          padding: '0.5em 2em',
          zIndex: '11',
          background: '#000',
          height: '85px',
        }}
      >
        <img
          src={logo}
          alt='FUTY BETA'
          width={140}
          style={{
            position: 'relative',
            left: '4vw',
          }}
        />
      </div>
      <div
        class='container'
        style={{
          marginLeft: '5%',
          width: '95%',
          fontFamily: "'RocGrotesk', sans-serif",
          fontWeight: '500',
          fontSize: '1.2em',
          color: '#fff',
          cursor: 'pointer',
          right: '4vw',
          height: '90%',
          overflowY: 'scroll',
        }}
      >
        <h1>FUTY Privacy Policy</h1>
        <h2>Introduction</h2>
        <p>
          Welcome to FUTY! Our policy explains how we process and protect your
          personal data when you engage with our fantasy football game,
          participate in competitions, and access rewards.
        </p>
        <h2>What Information We Collect</h2>
        <ul>
          <li>
            <strong>Personal Data</strong>: Your name, email address, and
            location.
          </li>
          <li>
            <strong>Game Data</strong>: Your team selections, scores, and
            standings.
          </li>
          <li>
            <strong>Payment Data</strong>: If you opt for paid memberships, we
            collect your payment and billing details.
          </li>
        </ul>
        <h2>How We Use Your Data</h2>
        <ul>
          <li>
            <strong>To Provide Services</strong>: Manage your account, offer
            game features, and deliver services you request.
          </li>
          <li>
            <strong>To Enhance Our Services</strong>: Analyse usage to improve
            and personalise your gaming experience.
          </li>
          <li>
            <strong>For Marketing</strong>: With your consent, to send you
            promotional material and updates about new game features.
          </li>
        </ul>
        <h2>How We Share Your Information</h2>
        <ul>
          <li>
            <strong>With Service Providers</strong>: Third-party companies that
            assist us in operating our game, such as server hosting and customer
            support.
          </li>
          <li>
            <strong>For Legal Reasons</strong>: If required by law or to protect
            the rights and safety of our players and company.
          </li>
        </ul>
        <h2>Your Rights and Choices</h2>
        <p>
          You may access, update, or delete your personal information via your
          account settings. You can also opt out of promotional communications
          at any time.
        </p>
        <h2>Data Security</h2>
        <p>
          We employ advanced security measures to protect your information
          against unauthorised access, alteration, and loss.
        </p>
        <h2>Cookies and Tracking Technologies</h2>
        <p>
          We utilise cookies to enhance your gaming experience, understand usage
          patterns, and support our marketing activities.
        </p>
        <h2>Changes to Our Privacy Policy</h2>
        <p>
          We may update this policy to reflect changes in our practices. If we
          make significant alterations, we will notify you through the game or
          by email.
        </p>
        <h2>Contact Us</h2>
        <p>
          For queries about this policy or your personal data, please contact
          our support team.
        </p>
      </div>
    </div>
  );
}
