import logo from '../images/Logo.png';
export default function TermsAndConditions() {
  const terms = [
    {
      title: '1. Introduction and Acceptance',
      content:
        'Welcome to FUTY! These Terms and Conditions govern your access to and use of FUTY\'s fantasy gaming services ("Services") available exclusively within the United Kingdom. By registering for and using the Services, you confirm your acceptance of these Terms and your agreement to be bound by them.',
    },
    {
      title: '2. Eligibility',
      content:
        'Services are strictly for users who are 18 years of age or older. By participating, you affirm that you meet this age requirement and that you are legally capable of entering into binding contracts.',
    },
    {
      title: '3. Account Registration and Subscription',
      content:
        'You must register for an account to use the Services, providing accurate and complete information. <br/> <strong>a. Account Creation:</strong> You must register for an account to use the Services, providing accurate and complete information. <br /> <strong>b. Subscriptions:</strong> <br /> <ul> <li> <strong>Monthly Subscription:</strong> Automatically renews each month. You may cancel the renewal at any time before the next billing cycle. </li> <li> <strong>Annual Subscription:</strong> Pay upfront and receive a 10% discount. This subscription is non-refundable. </li> </ul>',
    },
    {
      title: '4. Use of Services',
      content:
        "You are granted access to fantasy games where you can use tokens to participate. Details of the game mechanics, including visibility of opponents' teams after submission deadlines, are designed to enhance competition and learning.",
    },
    {
      title: '5. Intellectual Property',
      content:
        'While FUTY claims no ownership over user-generated content, you grant us a license to use your game data for operational purposes. All personal game histories are available on individual user profiles and are protected under these terms.',
    },
    {
      title: '6. Rewards and FUTYcoins',
      content:
        'Earn FUTYcoins by participating in games, which can be redeemed in our store for digital vouchers. The value and exchange rate of FUTYcoins for vouchers are subject to change and governed by the terms herein.',
    },
    {
      title: '7. Data Privacy and Security',
      content:
        'We commit to not sharing your personal data with third parties. All personal data is stored securely on our cloud and used solely to enhance game functionality and user experience.',
    },
    {
      title: '8. Promotions',
      content:
        'From time to time, we may run promotions in compliance with UK law. Specific terms and conditions for each promotion will be available on our platform.',
    },
    {
      title: '9. Service Modifications and Downtime',
      content:
        'We reserve the right to modify or discontinue any part of the Services with prior notice through email and app notifications. We will not be liable for any modification, suspension, or discontinuation of the Services.',
    },
    {
      title: '10. Mediation and Dispute Resolution',
      content:
        'In the event of a dispute, parties agree to seek resolution through mediation in accordance with UK law, prior to pursuing any other legal remedies.',
    },
    {
      title: '11. Termination and Cancellation',
      content:
        'You may cancel your subscription at any time. FUTY reserves the right to terminate or suspend your account if you breach these terms, with or without notice.',
    },
    {
      title: '12. Limitation of Liability',
      content:
        'FUTY shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.',
    },
    {
      title: '13. Governing Law',
      content:
        'These Terms shall be governed by and construed in accordance with the laws of the United Kingdom.',
    },
    {
      title: '14.General Provisions',
      content:
        'If any provision of these Terms is held to be invalid or unenforceable, that provision will be enforced to the maximum extent permissible, and the other provisions of these Terms will remain in full force and effect.',
    },
    {
      title: '15. Contact Us',
      content:
        'For any questions regarding these Terms, please contact our support team through the contact details provided on our platform.',
    },
  ];
  return (
    <div
      style={{
        backgroundColor: 'var(--background-primary)',
        color: 'white',
      }}
      // className="overflow-y-scroll"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          top: '0px',
          width: 'calc(100vw - 4em)',
          padding: '0.5em 2em',
          zIndex: '11',
          background: '#000',
          height: '85px',
        }}
      >
        <img
          src={logo}
          alt="FUTY BETA"
          width={140}
          style={{
            position: 'relative',
            left: '4vw',
          }}
        />
      </div>
      <h2 className="h-auto font-rocGrotesk text-[24px] text-[var(--primary-green)] px-4">
        FUTY Terms and Conditions
      </h2>
      <ol className="flex flex-col h-full gap-4 px-4 overflow-y-scroll">
        {terms.map((term, index) => (
          <li key={index}>
            <h3 className="text-[18px] font-interSemi text-white">
              {term.title}
            </h3>
            <div
              className="text-[12px] font-inter text-[#b9b9b9]"
              dangerouslySetInnerHTML={{ __html: term.content }}
            />
          </li>
        ))}
      </ol>
    </div>
  );
}
