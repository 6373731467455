import React from 'react';
import Appscreen1 from '../../images/AppScreen1.png';
import Appscreen2 from '../../images/AppScreen2.png';
import Appscreen3 from '../../images/AppScreen3.png';
import Appscreen4 from '../../images/AppScreen4.png';
import Appscreen5 from '../../images/AppScreen5.png';
import Appscreen6 from '../../images/AppScreen6.png';

export default function AppScreensImageCarousel() {
  return (
    <>
      <div className="w-full overflow-x-scroll flex gap-[10px] py-4">
        {[
          Appscreen1,
          Appscreen2,
          Appscreen3,
          Appscreen4,
          Appscreen5,
          Appscreen6,
        ].map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`App_Screen_${index + 1}`}
            className="w-1/2 h-auto rounded-[12px] border-[1px] border-[var(--primary-green)]"
          />
        ))}
      </div>
      <span className="w-full text-[#C0EB4B] text-[12px] text-right font-rocGrotesk">
        SWIPE -->
      </span>
    </>
  );
}
