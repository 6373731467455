import { useState } from 'react';
export default function GetMobile({ changeScreen, changePhoneNumber }) {
  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] =
    useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');

  async function sendOtp() {
    console.log('OTP sent');
    try {
      let formData = {
        mobile: '+44' + phoneNumber,
      };
      if (!formData.mobile?.length > 6) {
        alert('please enter a proper UK mobile number');
        return;
      }
      setShouldRenderSubmitButton(false);
      const url = 'https://api.futy.uk/api/v1/user/sendLoginOTP';
      // const url = 'http://localhost:4000/api/v1/user/sendLoginOTP';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console
      if (responseData.data?.error) {
        alert(responseData.data?.error);
        setShouldRenderSubmitButton(true);
        return;
      }
      if (responseData.data?.message) alert(responseData.data?.message);
      setShouldRenderSubmitButton(true);
      changePhoneNumber(formData?.mobile);
      changeScreen('GetOtp');
    } catch (error) {
      console.error('Error:', error);
      // Handle error state
      setShouldRenderSubmitButton(true);
    }
  }
  const mobileFormStyles = {
    wrapper1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      displaywidth: '100vw',
      height: '100vh',
      zIndex: 1,
    },
    wrapperStyle: {
      width: '360px',
      minHeight: '280px',
      maxHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderRadius: '1em',
      backgroundColor: 'rgba(24, 24, 27, 0.7)',
      border: '0px',
    },
  };
  return (
    <div
      id='outerWrapper'
      style={mobileFormStyles.wrapper1}
    >
      <div style={mobileFormStyles.wrapperStyle}>
        <span
          style={{
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: 600,
            fontSize: '20px',
            color: '#fff',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          {' '}
          Please enter your phone number
        </span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            height: '35px',
            color: 'rgba(255,255,255,0.5)',
          }}
        >
          <input
            type='text'
            id='countryCode'
            name='countryCode'
            value='+44'
            disabled='true'
            style={{
              width: '12.5%',
              border: '0.1em rgba(255,255,255,0.2) solid',
              backgroundColor: 'rgba(255,255,255,0.05)',
              borderRadius: '0.5em',
              height: '100%',
              marginBottom: '0.5em',
              fontFamily: "'Inter',sans-serif",
              textAlign: 'center',
              fontSize: '16px',
            }}
          ></input>
          <input
            type='number'
            id='phone'
            name='phone'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{
              width: '75%',
              border: '0.1em rgba(255,255,255,0.2) solid',
              paddingLeft: '12px',
              backgroundColor: 'rgba(255,255,255,0.05)',
              borderRadius: '0.5em',
              height: '100%',
              marginBottom: '0.5em',
              fontFamily: "'Inter',sans-serif",
              fontSize: '16px',
              color: 'rgba(255,255,255,0.5)',
            }}
            placeholder='Enter Phone Number'
          ></input>
        </div>
        <button
          onClick={sendOtp}
          style={{
            width: '95%',
            height: '35px',
            borderWidth: '0',
            borderRadius: '0.5em',
            backgroundColor: 'var(--primary-green)',
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: '600',
            fontSize: '16px',
            color: '#ffffff',
            textAlign: 'center',
            cursor: 'pointer',
            letterSpacing: '2px',
            marginBottom: '10px',
            padding: '0.25em 0em',
            zIndex: 2,
          }}
        >
          {shouldRenderSubmitButton ? (
            <span>CONTINUE</span>
          ) : (
            <img
              src='https://fonts.gstatic.com/s/e/notoemoji/latest/2699_fe0f/512.gif'
              alt='⚙'
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
            />
          )}
        </button>
        <span
          style={{
            fontFamily: "'Inter', sans-serif",
            fontWeight: 400,
            fontSize: '10px',
            color: '#fff',
            textAlign: 'center',
            marginBottom: '0.75em',
            width: '90%',
          }}
        >
          By proceeding, you consent to get calls, WhatsApp or SMS messages,
          including by automated means, from FUTY.
        </span>
      </div>
    </div>
  );
}
