import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import DeleteAccount from './pages/DeleteAccount';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Var2D from './pages/variants/homeVar2/Var2D';
import TermsAndConditions from './pages/TermsAndConditions';
import DownloadApp from './pages/DownloadApp';
import SearchReferralCode from './pages/Search';
import JerseyGiveaway from './pages/giveaway/JerseyGiveaway';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path='/'
          exact
          element={<App />}
        />
        <Route
          path='/var2'
          element={<Var2D />}
        />
        <Route
          path='/deleteAccount'
          element={<DeleteAccount />}
        />
        <Route
          path='/privacyPolicy'
          element={<PrivacyPolicy />}
        />
        <Route
          path='/termsAndConditions'
          element={<TermsAndConditions />}
        />
        <Route
          path='/downloadApp'
          element={<DownloadApp />}
        />
        <Route
          path='/campaign'
          element={<SearchReferralCode />}
        />
        <Route
          path='/giveaway'
          element={<JerseyGiveaway />}
        />
        <Route
          path='*'
          element={<App />}
        />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
