import React from 'react';
import PhoneScreen from '../../../images/GiveawayPageAppScreen4.png';
import SqaureBackground from '../../../images/SqaureBackgroundWhyFuty.png';
import AestheticEllipse from '../../../images/WhyFutyEllipse.png';
import RectangleOrange from '../../../images/RectangleOrange.png';

export default function WhyFuty() {
  return (
    <section className="max-h-full w-screen flex flex-col items-center text-[32px] lg:text-[48px] text-[#ffffff] font-['RocGrotesk'] gap-10 relative">
      <img
        src={AestheticEllipse}
        alt="ellipse"
        className="absolute z-[1] w-4/5 lg:w-1/2 h-auto left-0"
      />
      <h1
        id="mainHeading"
        className="text-[#009A57] relative z-[1]"
      >
        <img
          src={RectangleOrange}
          alt="PinkAestheticRectangle"
          className="w-[24px] h-[24px] lg:w-[32px] lg:h-[32px] absolute z-[-1] left-[-2.75vh] top-[1vh]"
        />
        Why FUTY?
      </h1>
      <div
        id="mainBody"
        className="flex flex-col lg:grid lg:grid-cols-3 gap-8 lg:gap-4 h-full w-5/6  text-start lg:text-center"
      >
        <div className="flex flex-col justify-evenly font-rocgrotesk text-[20px] lg:text-[24px] px-2 max-w-[450px] z-[1] gap-8 lg:gap-0">
          <span>
            <span className="text-[#ffe82c]">+</span> Immersive pointing system
            with 30+ events to win/lose points on
          </span>
          <span>
            <span className="text-[#ffe82c]">+</span> Premier matches
          </span>
        </div>
        <div className="hidden justify-center items-center relative lg:flex">
          <img
            src={SqaureBackground}
            alt="Squarebackground"
            className="absolute scale-150 z"
          />
          <img
            src={PhoneScreen}
            alt="AppPhoneScreen"
            className="w-4/5 h-auto max-w-[450px] z-[1]"
          />
        </div>
        <div className="flex flex-col justify-evenly font-rocgrotesk text-[20px] lg:text-[24px] px-2 max-w-[450px] z-[1] gap-8 lg:gap-0">
          <span>
            <span className="text-[#ffe82c]">+</span> Match on Match winners, No
            season long committment
          </span>
          <span>
            <span className="text-[#ffe82c]">+</span> Live leaderboard
          </span>
        </div>
      </div>
    </section>
  );
}
