import { useEffect } from 'react';

export default function DownloadApp() {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // eslint-disable-next-line no-undef
      fbq('track', 'SmsRedirect', {
        platform: 'iOS',
      });
      // eslint-disable-next-line no-undef
      ttq.track('SmsRedirect', {
        platform: 'iOS',
      });
      // eslint-disable-next-line no-undef
      gtag('event', 'SmsRedirect', {
        platform: 'iOS',
      });
      window.location.href = 'https://apps.apple.com/us/app/futy/id6497875745';
    } else if (/Android/i.test(userAgent)) {
      // eslint-disable-next-line no-undef
      fbq('track', 'SmsRedirect', {
        platform: 'Android',
      });
      // eslint-disable-next-line no-undef
      ttq.track('SmsRedirect', {
        platform: 'Android',
      });
      // eslint-disable-next-line no-undef
      gtag('event', 'SmsRedirect', {
        platform: 'Android',
      });
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.futy.app'; // to be updated
    } else {
      // eslint-disable-next-line no-undef
      fbq('track', 'SmsRedirect', {
        platform: 'Other',
      });
      // eslint-disable-next-line no-undef
      ttq.track('SmsRedirect', {
        platform: 'Other',
      });
      // eslint-disable-next-line no-undef
      gtag('event', 'SmsRedirect', {
        platform: 'Other',
      });
      window.location.href = 'https://www.futy.uk';
    }
  }, []);

  // Render nothing or a loading indicator since the redirection happens in useEffect

  return null;
}
