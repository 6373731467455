import { useState } from 'react';
export default function ConfirmDelete({ accessToken }) {
  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] =
    useState(true);
  const [deletionSuccess, setDeletionSuccess] = useState(false);

  async function sendOtp() {
    console.log('Confirm delete', accessToken);
    try {
      if (!accessToken) {
        alert('Please verify mobile number again');
        return;
      }
      setShouldRenderSubmitButton(false);
      const url = 'https://api.futy.uk/api/v1/user/remove';
      // const url = 'http://localhost:4000/api/v1/user/remove';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: accessToken,
        },
        // body: JSON.stringify(formData),
      });
      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console
      if (responseData.data?.error) {
        alert(responseData.data?.error);
        setShouldRenderSubmitButton(true);
        return;
      }
      if (responseData.error) {
        alert(responseData.error);
        setShouldRenderSubmitButton(true);
        return;
      }
      setShouldRenderSubmitButton(true);
      setDeletionSuccess(true);
    } catch (error) {
      console.error('Error:', error);
      // Handle error state
      setShouldRenderSubmitButton(true);
    }
  }
  const mobileFormStyles = {
    wrapper1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      displaywidth: '100vw',
      height: '100vh',
      zIndex: 1,
    },
    wrapperStyle: {
      width: '360px',
      minHeight: '280px',
      maxHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '1em',
      backgroundColor: 'rgba(24, 24, 27, 0.7)',
      border: '0px',
    },
  };

  return deletionSuccess ? (
    <div
      id='outerWrapper'
      style={mobileFormStyles.wrapper1}
    >
      <div style={mobileFormStyles.wrapperStyle}>
        <span
          style={{
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: 600,
            fontSize: '20px',
            color: '#fff',
            textAlign: 'center',
            marginTop: '10px',
            width: '95%',
          }}
        >
          {' '}
          Your account has been successfully deleted. <br /> We will miss you.
        </span>
      </div>
    </div>
  ) : (
    <div
      id='outerWrapper'
      style={mobileFormStyles.wrapper1}
    >
      <div style={mobileFormStyles.wrapperStyle}>
        <span
          style={{
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: 600,
            fontSize: '20px',
            color: '#fff',
            textAlign: 'center',
            marginTop: '10px',
          }}
        >
          {' '}
          Please note that
        </span>
        <span
          style={{
            fontFamily: "'Inter', sans-serif",
            fontWeight: 400,
            fontSize: '12px',
            color: 'rgba(255,255,255,0.5)',
            width: '90%',
          }}
        >
          <ul style={{ padding: '0px 18px' }}>
            <li>
              Once you delete your account, it will be immediately deactivated.
              After 30 days it will be permanently deleted, and any unused
              credits and coins will be removed.
            </li>
            <li>
              FUTY may retain certain information after account deletion as
              required or permitted by law.
            </li>
          </ul>
        </span>

        <button
          onClick={sendOtp}
          style={{
            width: '95%',
            height: '35px',
            borderWidth: '0',
            borderRadius: '0.5em',
            backgroundColor: 'var(--primary-green)',
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: '600',
            color: '#fff',
            textAlign: 'center',
            cursor: 'pointer',
            letterSpacing: '2px',
            margin: '20px 0px 10px 0px',
            padding: '0.25em 0em',
            zIndex: 1,
            fontSize: '16px',
          }}
        >
          {shouldRenderSubmitButton ? (
            <span>CONFIRM</span>
          ) : (
            <img
              src='https://fonts.gstatic.com/s/e/notoemoji/latest/2699_fe0f/512.gif'
              alt='⚙'
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
            />
          )}
        </button>
      </div>
    </div>
  );
}
