import React, { useState, useEffect } from 'react';
import GetMobile from '../components/GetMobile';
import OtpForm from '../components/OtpForm';
import ConfirmDelete from '../components/ConfirmDelete';
import logo from '../images/Logo.png';
import SearchReferralCode from '../components/SearchReferralScreen';
export default function DeleteAccount() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    console.log(window.innerWidth);

    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < window.innerHeight); // Adjust threshold as needed
    };

    updateIsMobile(); // Check initially
    window.addEventListener('resize', updateIsMobile);

    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);
  const [screen, setScreen] = useState('GetMobile');
  const changeScreen = (newScreen) => {
    setScreen(newScreen);
    console.log('1', newScreen);
  };
  const changePhoneNumber = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    console.log('1', phoneNumber);
  };
  const changeAccessToken = (accessToken) => {
    setAccessToken(accessToken);
    console.log('12345', accessToken);
  };
  console.log('HI', screen);
  const styles = {
    ellipse33: {
      width: '28em',
      position: 'absolute',
      height: '33em',
      right: 'calc(35vw)',
      top: 'calc(15vh)',
      background:
        'linear-gradient(98.24deg, rgb(0, 104, 255) 0%, rgb(255, 84, 0) 34.51%, rgb(236, 0, 130) 57.27%, rgb(178, 239, 1) 100%)',
      opacity: '0.4',
      filter: 'blur(100px)',
      transform: ' rotate(55.83deg)',
      pointerEvents: 'none',
    },
  };
  const mobileStyles = {
    ellipse33: {
      position: 'absolute',
      width: '50em',
      height: '40em',
      top: 'calc(425px)',
      background:
        'linear-gradient(98.24deg, #0068FF 0%, #FF5400 34.51%, #EC0082 57.27%, #B2EF01 100%)',
      opacity: '0.45',
      filter: 'blur(11em)',
      transform: 'rotate(145.83deg)',
    },
  };
  return (
    <div
      style={{
        backgroundColor: 'var(--background-primary)',
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: '0px',
          width: 'calc(100vw - 4em)',
          padding: '0.5em 2em',
          zIndex: '11',
          background: '#000',
          height: '85px',
        }}
      >
        <img
          src={logo}
          alt='FUTY BETA'
          width={140}
          style={{
            position: 'relative',
            left: '4vw',
          }}
        />
      </div>
      <div style={isMobile ? mobileStyles.ellipse33 : styles.ellipse33}></div>

      <SearchReferralCode
        changeScreen={changeScreen}
        changePhoneNumber={changePhoneNumber}
      />

      {screen === 'GetOtp' && (
        <OtpForm
          changeScreen={changeScreen}
          changeAccessToken={changeAccessToken}
          phoneNumber={phoneNumber}
        />
      )}
      {screen === 'ConfirmDelete' && (
        <ConfirmDelete accessToken={accessToken} />
      )}
    </div>
  );
}
