import { useState } from 'react';
export default function OtpForm({
  changeScreen,
  phoneNumber,
  changeAccessToken,
}) {
  const [shouldRenderSubmitButton, setShouldRenderSubmitButton] =
    useState(true);
  const [otp, setOtp] = useState('');

  async function sendOtp() {
    console.log('Confirm delete');
    console.log('OTP sent');
    try {
      let formData = {
        mobile: phoneNumber,
        otp: otp,
      };
      if (!formData.mobile && !formData.otp) {
        alert('please enter a valid OTP');
        return;
      }
      setShouldRenderSubmitButton(false);
      const url = 'https://api.futy.uk/api/v1/user/login';
      // const url = 'http://localhost:4000/api/v1/user/login';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json(); // Extract JSON data from response

      console.log(responseData); // Output the response data to console
      if (responseData.data?.error) {
        alert(responseData.data?.error + ' Please try again.');
        setShouldRenderSubmitButton(true);
        return;
      }
      if (responseData.error) {
        alert(responseData.error);
        setShouldRenderSubmitButton(true);
        return;
      }
      if (responseData.data?.message) alert(responseData.data?.message);
      setShouldRenderSubmitButton(true);
      if (responseData.data?.accessToken) {
        changeAccessToken(responseData.data?.accessToken);
        changeScreen('ConfirmDelete');
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error state
      setShouldRenderSubmitButton(true);
    }
  }
  const mobileFormStyles = {
    wrapper1: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      displaywidth: '100vw',
      height: '100vh',
      zIndex: 1,
    },
    wrapperStyle: {
      width: '360px',
      minHeight: '280px',
      maxHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderRadius: '1em',
      backgroundColor: 'rgba(24, 24, 27, 0.7)',
      border: '0px',
    },
  };
  return (
    <div
      id='outerWrapper'
      style={mobileFormStyles.wrapper1}
    >
      <div style={mobileFormStyles.wrapperStyle}>
        <span
          style={{
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: 600,
            color: '#fff',
            textAlign: 'center',
            marginTop: '10px',
            fontSize: '20px',
          }}
        >
          {' '}
          Enter the OTP received
        </span>

        <input
          type='number'
          id='otp'
          name='otp'
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          style={{
            width: '80%',
            border: '0.1em rgba(255,255,255,0.2) solid',
            paddingLeft: '12px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            borderRadius: '0.5em',
            marginBottom: '0.5em',
            fontFamily: "'Inter',sans-serif",
            fontSize: '16px',
            height: '35px',
            color: '#fff',
          }}
          placeholder='Enter OTP'
          required
        ></input>

        <button
          onClick={sendOtp}
          style={{
            width: '85%',
            height: '35px',
            borderWidth: '0',
            borderRadius: '0.5em',
            backgroundColor: 'var(--primary-green)',
            fontFamily: "'RocGrotesk', sans-serif",
            fontWeight: '600',
            fontSize: '16px',
            color: '#fff',
            textAlign: 'center',
            cursor: 'pointer',
            letterSpacing: '2px',
            marginBottom: '10px',
            padding: '0.25em 0em',
            zIndex: 1,
          }}
        >
          {shouldRenderSubmitButton ? (
            <span>VERIFY OTP</span>
          ) : (
            <img
              src='https://fonts.gstatic.com/s/e/notoemoji/latest/2699_fe0f/512.gif'
              alt='⚙'
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
            />
          )}
        </button>
      </div>
    </div>
  );
}
