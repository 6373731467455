import { useState } from 'react';
import faqSupportSqaure from '../images/faq-bg-square.png';
import { FAQ_QNA } from '../utils/constants';
import plusPng from '../images/plus.png';
import minusPng from '../images/minus.png';

export default function FaqSection() {
  const viewPortWidth = window.innerWidth;
  const styles = {
    mainPageContainer: {
      width: '90vw',
      overflow: 'hidden',
    },
  };
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      style={{
        ...styles.mainPageContainer,
        minHeight: viewPortWidth < 750 ? '325px' : '600px',
        maxWidth: '1100px',
      }}
      id='faqSubPageContainer'
    >
      <style>
        {`
        .delete-account, .delete-account:visited {
          color: var(--primary-green);
          text-decoration: 'underline';
          text-underline-offset: 2px;
        }
      `}
      </style>
      <img
        src={faqSupportSqaure}
        alt='squares'
        style={{
          position: 'absolute',
          left: '0',
          width: '10vw',
          height: '60vh',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        <span
          style={{
            padding: '2rem 0',
            color: 'white',
            fontFamily: "'RocGrotesk',sans-serif",
            fontWeight: '600',
            fontSize: viewPortWidth < 750 ? '4em' : '3.125em',
            textAlign: 'center',
          }}
        >
          FAQ
        </span>
        <div
          style={{
            width: '85%',
            borderRadius: '1em',
          }}
        >
          <div>
            {FAQ_QNA.map((section, index) => (
              <div key={index}>
                <div
                  style={{
                    padding: '1rem',
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gridTemplateColumns: '90% 10%',
                    border: '0.0625em solid rgba(255,255,255,0.1)',
                    borderTopLeftRadius: index === 0 ? '1em' : '0',
                    borderTopRightRadius: index === 0 ? '1em' : '0',
                    borderBottomLeftRadius:
                      index === FAQ_QNA.length - 1 ? '1em' : '0',
                    borderBottomRightRadius:
                      index === FAQ_QNA.length - 1 ? '1em' : '0',
                    backgroundColor:
                      expandedIndex === index ? '#212121' : '#000000',
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontFamily: "'Inter',sans-serif",
                        fontWeight: '600',
                        fontSize: viewPortWidth < 750 ? '1.5em' : '1rem',

                        textAlign: 'center',
                      }}
                    >
                      {section.question}
                    </span>
                    {expandedIndex === index && (
                      <div>
                        <p
                          style={{
                            fontFamily: "'Inter',sans-serif",
                            fontWeight: 'lighter',
                            fontSize:
                              viewPortWidth < 750 ? '1.4em' : '0.875rem',
                            letterSpacing: '0.0625em',
                            color: '#bfbfbf',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: section.answer,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={expandedIndex === index ? minusPng : plusPng}
                      alt='openCloseSection'
                      onClick={() => toggleAccordion(index)}
                      style={{
                        cursor: 'pointer',
                        marginBottom: '0.3125em',
                        width: '1.5em',
                        height: '1.5em',
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
