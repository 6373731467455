import React from 'react';
import PhoneScreen1 from '../../../images/GiveawayPageAppScreen1.png';
import PhoneScreen2 from '../../../images/GiveawayPageAppScreen2.png';
import PhoneScreen3 from '../../../images/GiveawayPageAppScreen3.png';
import RectangleGreen from '../../../images/RectangleGreen.png';
import AestheticEllipse from '../../../images/GetInvolvedEllipse.png';

export default function GetInvloved() {
  return (
    <section className="max-h-full w-screen flex flex-col items-center text-[32px] lg:text-[48px] text-[#ffffff] font-['RocGrotesk'] gap-10 relative">
      <img
        src={AestheticEllipse}
        alt="ellipse"
        className="hidden absolute w-3/5 h-auto bottom-[-30vh] lg:inline-block"
      />
      <img
        src={AestheticEllipse}
        alt="ellipse"
        className="absolute h-1/3 w-auto right-[-20vh] top-[65vh] lg:hidden"
      />
      <h1
        id="mainHeading"
        className="text-[#009A57] relative z-[1]"
      >
        <img
          src={RectangleGreen}
          alt="GreenAestheticRectangle"
          className="w-[24px] h-[24px] lg:w-[32px] lg:h-[32px] absolute z-[-1] left-[-2.5vh] top-[1vh]"
        />
        Get Involved
      </h1>
      <div
        id="mainBody"
        className="h-full w-5/6 flex flex-col lg:flex-row gap-[60px] text-[18px] lg:text-[32px] text-center z-[1]"
      >
        <div className="flex flex-col gap-[6px] items-center">
          <img
            src={PhoneScreen1}
            alt="PhoneScreen1"
            className="w-4/5 h-auto mb-8"
          ></img>
          <h2 className="mb-2">Step 1</h2>
          <p className="text-[16px] lg:text-[18px] font-inter">
            Download FUTY and register{' '}
          </p>
        </div>

        <div className="flex flex-col gap-[8px] items-center">
          {' '}
          <img
            src={PhoneScreen2}
            alt="PhoneScreen2"
            className="w-4/5 h-auto mb-8"
          ></img>
          <h2 className="mb-2">Step 2</h2>
          <p className="text-[16px] lg:text-[18px] font-inter">
            Create your team for any match in week 1 of the Premier League
          </p>
        </div>
        <div className="flex flex-col gap-[8px] items-center">
          {' '}
          <img
            src={PhoneScreen3}
            alt="PhoneScreen3"
            className="w-4/5 h-auto mb-8"
          ></img>
          <h2 className="mb-2">Step 3</h2>
          <p className="text-[16px] lg:text-[18px] font-inter">
            The FUTY team will get in touch if you are the lucky winner
          </p>
        </div>
      </div>
    </section>
  );
}
